@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
.discord-messages {
  color: #fff;
  background-color: #36393e;
  font-size: 16px;
  font-family: sans-serif;
  /* line-height: 170%; */
  width: 600px;
  border: 1px solid hsla(0, 0%, 100%, 0.05);
}
.discord-messages.discord-light-theme {
  color: #747f8d;
  background-color: #f7f7f7;
  border-color: #dedede;
}
.discord-message .discord-author-info {
  color: #fff;
  display: inline-flex;
  align-items: center;
  font-size: 15px;
}
.discord-message .discord-author-info .discord-author-username {
  font-size: 1.1em;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.discord-light-theme
  .discord-message
  .discord-author-info
  .discord-author-username {
  color: #23262a;
}
.discord-message .discord-author-info .discord-bot-tag {
  background-color: #7289da;
  font-size: 0.65em;
  margin-left: 5px;
  padding: 3px;
  border-radius: 3px;
  line-height: 100%;
  text-transform: uppercase;
}
.discord-light-theme .discord-message .discord-author-info .discord-bot-tag {
  color: #fff;
}
.discord-compact-mode .discord-message .discord-author-info {
  display: inline-flex;
  flex-direction: row-reverse;
}
.discord-compact-mode
  .discord-message
  .discord-author-info
  .discord-author-username {
  margin-left: 4px;
  margin-right: 4px;
}
.discord-compact-mode .discord-message .discord-author-info .discord-bot-tag {
  margin-left: 0;
  margin-right: 5px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 0.6em;
}
.discord-message {
  color: #dcddde;
  display: flex;
  font-size: 0.9em;
  margin: 1em 0;
  padding: 0.25em 1em 0;
}
.discord-message:hover {
  background-color: #32353b;
}
.discord-light-theme .discord-message {
  color: #2e3338;
}
.discord-light-theme .discord-message:hover {
  background-color: #fafafa;
}
.discord-message a {
  color: #0096cf;
  font-weight: 400;
  text-decoration: none;
}
.discord-light-theme .discord-message a {
  color: #00b0f4;
}
.discord-message a:hover {
  text-decoration: underline;
}
.discord-message .discord-author-avatar {
  margin-top: 1px;
  margin-right: 16px;
  min-width: 40px;
}
.discord-message .discord-author-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.discord-message .discord-message-timestamp {
  color: hsla(0, 0%, 100%, 0.2);
  font-size: 12px;
  margin-left: 3px;
}
.discord-message .discord-message-edited {
  color: hsla(0, 0%, 100%, 0.2);
  font-size: 10px;
}
.discord-message .discord-message-content {
  width: 100%;
  line-height: 160%;
  font-weight: 400;
  overflow-wrap: anywhere;
}
.discord-message .discord-message-body {
  position: relative;
}
.discord-compact-mode.discord-light-theme
  .discord-message:hover
  .discord-message-timestamp,
.discord-compact-mode .discord-message:hover .discord-message-timestamp,
.discord-light-theme .discord-message .discord-message-timestamp {
  color: #99aab5;
}
.discord-compact-mode.discord-light-theme
  .discord-message
  .discord-message-timestamp {
  color: #d1d9de;
}
.discord-compact-mode .discord-message {
  margin: 0.15em 0;
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.discord-compact-mode .discord-author-avatar {
  display: none;
}
.discord-compact-mode .discord-message-body {
  margin-left: 0.25em;
}
.discord-embed {
  color: #dcddde;
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 150%;
}
.discord-light-theme .discord-embed {
  color: #2e3338;
}
.discord-embed .discord-left-border {
  background-color: #202225;
  flex-shrink: 0;
  width: 4px;
  border-radius: 4px 0 0 4px;
}
.discord-light-theme .discord-embed .discord-left-border {
  background-color: #e3e5e8;
}
.discord-embed .discord-embed-container {
  background-color: #2f3136;
  display: flex;
  flex-direction: column;
  max-width: 520px;
  padding: 4px 4px 4px 4px;
  border: 1px solid rgba(46, 48, 54, 0.6);
  border-radius: 0 4px 4px 0;
}
.discord-light-theme .discord-embed .discord-embed-container {
  background-color: hsla(0, 0%, 97.6%, 0.3);
  border-color: hsla(0, 0%, 80.4%, 0.3);
}
.discord-embed .discord-embed-content {
  display: flex;
}
.discord-embed .discord-embed-thumbnail {
  max-width: 80px;
  max-height: 80px;
  margin-left: 16px;
  margin-top: 8px;
  border-radius: 4px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: top center;
  object-position: top center;
}
.discord-embed .discord-embed-author {
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-top: 8px;
}
.discord-light-theme .discord-embed .discord-embed-author {
  color: #4f545c;
}
.discord-embed .discord-embed-author a {
  color: #fff;
}
.discord-light-theme .discord-embed .discord-embed-author a {
  color: #4f545c;
}
.discord-embed .discord-embed-author .discord-author-image {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
}
.discord-embed .discord-embed-title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
}
.discord-embed .discord-embed-title a {
  color: #00b0f4;
  font-weight: 600;
}
.discord-embed .discord-embed-description {
  margin-top: 8px;
}
.discord-embed .discord-embed-image {
  max-width: 100%;
  margin-top: 16px;
  border-radius: 4px;
}
.discord-embed .discord-embed-footer {
  color: #72767d;
  display: flex;
  align-items: center;
  font-size: 0.85em;
  margin-top: 8px;
}
.discord-embed .discord-embed-footer .discord-footer-image {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
}
.discord-embed .discord-embed-footer .discord-footer-separator {
  color: #3b3c42;
  font-weight: 700;
  margin: 0 4px;
}
.discord-light-theme
  .discord-embed
  .discord-embed-footer
  .discord-footer-separator {
  color: #e4e4e4;
}
.discord-embed .discord-embed-fields {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}
.discord-embed .discord-embed-field {
  min-width: 100%;
  margin-top: 5px;
}
.discord-embed .discord-embed-field.discord-inline-field {
  flex-grow: 1;
  flex-basis: auto;
  min-width: 100px;
}
.discord-embed .discord-embed-field .discord-field-title {
  color: #72767d;
  font-weight: 500;
  margin-bottom: 2px;
}
.discord-light-theme .discord-embed .discord-embed-field .discord-field-title {
  color: #747f8d;
}
.discord-message .discord-mention {
  color: #7289da;
  background-color: rgba(114, 137, 218, 0.1);
  font-weight: 500;
  padding: 0 1px;
}
.discord-message .discord-mention:hover {
  color: #fff;
  background-color: rgba(114, 137, 218, 0.7);
}
.discord-message.discord-highlight-mention {
  background-color: rgba(250, 166, 26, 0.05);
  position: relative;
}
.discord-message.discord-highlight-mention:before {
  content: "";
  background-color: #faa61a;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
}
.discord-message.discord-highlight-mention:hover {
  background-color: rgba(250, 166, 26, 0.1);
}
.discord-message.discord-highlight-mention .discord-mention {
  background-color: unset !important;
}
.discord-message.discord-highlight-mention .discord-mention:hover {
  color: #7289da;
  text-decoration: underline;
}
.discord-messages {
  font-family: Roboto, sans-serif;
}
