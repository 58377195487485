@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
@import url(https://fonts.cdnfonts.com/css/made-tommy-outline);
@-webkit-keyframes scaleUpDown {
  0%,
  100% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1);
  }
  50%,
  90% {
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
  }
  75% {
    -webkit-transform: scaleY(0.95);
            transform: scaleY(0.95);
  }
  80% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
  }
}

@keyframes scaleUpDown {
  0%,
  100% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1);
  }
  50%,
  90% {
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
  }
  75% {
    -webkit-transform: scaleY(0.95);
            transform: scaleY(0.95);
  }
  80% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
  }
}

@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: skewX(0) scale(1);
            transform: skewX(0) scale(1);
  }
  50% {
    -webkit-transform: skewX(5deg) scale(0.9);
            transform: skewX(5deg) scale(0.9);
  }
}

@keyframes shake {
  0%,
  100% {
    -webkit-transform: skewX(0) scale(1);
            transform: skewX(0) scale(1);
  }
  50% {
    -webkit-transform: skewX(5deg) scale(0.9);
            transform: skewX(5deg) scale(0.9);
  }
}

@-webkit-keyframes particleUp {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}

@keyframes particleUp {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}

@-webkit-keyframes glow {
  0%,
  100% {
    background-color: #ef5a00;
  }
  50% {
    background-color: #ff7800;
  }
}

@keyframes glow {
  0%,
  100% {
    background-color: #ef5a00;
  }
  50% {
    background-color: #ff7800;
  }
}
.fire {
  position: relative;
  /* margin-top: 40vh; */
  width: 40px;
  height: 40px;
  background-color: transparent;
}

.fire-main {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-animation: scaleUpDown 3s ease-out;
          animation: scaleUpDown 3s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-main .main-fire {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    farthest-corner at 10px 0,
    #d43300 0%,
    #ef5a00 95%
  );
  -webkit-transform: scaleX(0.8) rotate(45deg);
          transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 60% 40%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
}

.fire-main .particle-fire {
  position: absolute;
  top: 60%;
  left: 45%;
  width: 10px;
  height: 10px;
  background-color: #ef5a00;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
  -webkit-animation: particleUp 2s ease-out 0;
          animation: particleUp 2s ease-out 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-right {
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-animation: shake 2s ease-out 0;
          animation: shake 2s ease-out 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-right .main-fire {
  position: absolute;
  top: 15%;
  right: -25%;
  width: 80%;
  height: 80%;
  background-color: #ef5a00;
  -webkit-transform: scaleX(0.8) rotate(45deg);
          transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 60% 40%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
}

.fire-right .particle-fire {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 15px;
  height: 15px;
  background-color: #ef5a00;
  -webkit-transform: scaleX(0.8) rotate(45deg);
          transform: scaleX(0.8) rotate(45deg);
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
  -webkit-animation: particleUp 2s ease-out 0;
          animation: particleUp 2s ease-out 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-left {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-animation: shake 3s ease-out 0;
          animation: shake 3s ease-out 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-left .main-fire {
  position: absolute;
  top: 15%;
  left: -20%;
  width: 80%;
  height: 80%;
  background-color: #ef5a00;
  -webkit-transform: scaleX(0.8) rotate(45deg);
          transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 60% 40%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
}

.fire-left .particle-fire {
  position: absolute;
  top: 10%;
  left: 20%;
  width: 10%;
  height: 10%;
  background-color: #ef5a00;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
  -webkit-animation: particleUp 3s infinite ease-out 0;
          animation: particleUp 3s infinite ease-out 0;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-bottom .main-fire {
  position: absolute;
  top: 30%;
  left: 20%;
  width: 75%;
  height: 75%;
  background-color: #ff7800;
  -webkit-transform: scaleX(0.8) rotate(45deg);
          transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 100% 40%;
  -webkit-filter: blur(10px);
          filter: blur(10px);
  -webkit-animation: glow 2s ease-out 0;
          animation: glow 2s ease-out 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}



.discord-messages {
  color: #fff;
  background-color: #36393e;
  font-size: 16px;
  font-family: sans-serif;
  /* line-height: 170%; */
  width: 600px;
  border: 1px solid hsla(0, 0%, 100%, 0.05);
}
.discord-messages.discord-light-theme {
  color: #747f8d;
  background-color: #f7f7f7;
  border-color: #dedede;
}
.discord-message .discord-author-info {
  color: #fff;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 15px;
}
.discord-message .discord-author-info .discord-author-username {
  font-size: 1.1em;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.discord-light-theme
  .discord-message
  .discord-author-info
  .discord-author-username {
  color: #23262a;
}
.discord-message .discord-author-info .discord-bot-tag {
  background-color: #7289da;
  font-size: 0.65em;
  margin-left: 5px;
  padding: 3px;
  border-radius: 3px;
  line-height: 100%;
  text-transform: uppercase;
}
.discord-light-theme .discord-message .discord-author-info .discord-bot-tag {
  color: #fff;
}
.discord-compact-mode .discord-message .discord-author-info {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.discord-compact-mode
  .discord-message
  .discord-author-info
  .discord-author-username {
  margin-left: 4px;
  margin-right: 4px;
}
.discord-compact-mode .discord-message .discord-author-info .discord-bot-tag {
  margin-left: 0;
  margin-right: 5px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 0.6em;
}
.discord-message {
  color: #dcddde;
  display: -webkit-flex;
  display: flex;
  font-size: 0.9em;
  margin: 1em 0;
  padding: 0.25em 1em 0;
}
.discord-message:hover {
  background-color: #32353b;
}
.discord-light-theme .discord-message {
  color: #2e3338;
}
.discord-light-theme .discord-message:hover {
  background-color: #fafafa;
}
.discord-message a {
  color: #0096cf;
  font-weight: 400;
  text-decoration: none;
}
.discord-light-theme .discord-message a {
  color: #00b0f4;
}
.discord-message a:hover {
  text-decoration: underline;
}
.discord-message .discord-author-avatar {
  margin-top: 1px;
  margin-right: 16px;
  min-width: 40px;
}
.discord-message .discord-author-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.discord-message .discord-message-timestamp {
  color: hsla(0, 0%, 100%, 0.2);
  font-size: 12px;
  margin-left: 3px;
}
.discord-message .discord-message-edited {
  color: hsla(0, 0%, 100%, 0.2);
  font-size: 10px;
}
.discord-message .discord-message-content {
  width: 100%;
  line-height: 160%;
  font-weight: 400;
  overflow-wrap: anywhere;
}
.discord-message .discord-message-body {
  position: relative;
}
.discord-compact-mode.discord-light-theme
  .discord-message:hover
  .discord-message-timestamp,
.discord-compact-mode .discord-message:hover .discord-message-timestamp,
.discord-light-theme .discord-message .discord-message-timestamp {
  color: #99aab5;
}
.discord-compact-mode.discord-light-theme
  .discord-message
  .discord-message-timestamp {
  color: #d1d9de;
}
.discord-compact-mode .discord-message {
  margin: 0.15em 0;
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.discord-compact-mode .discord-author-avatar {
  display: none;
}
.discord-compact-mode .discord-message-body {
  margin-left: 0.25em;
}
.discord-embed {
  color: #dcddde;
  display: -webkit-flex;
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 150%;
}
.discord-light-theme .discord-embed {
  color: #2e3338;
}
.discord-embed .discord-left-border {
  background-color: #202225;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 4px;
  border-radius: 4px 0 0 4px;
}
.discord-light-theme .discord-embed .discord-left-border {
  background-color: #e3e5e8;
}
.discord-embed .discord-embed-container {
  background-color: #2f3136;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 520px;
  padding: 4px 4px 4px 4px;
  border: 1px solid rgba(46, 48, 54, 0.6);
  border-radius: 0 4px 4px 0;
}
.discord-light-theme .discord-embed .discord-embed-container {
  background-color: hsla(0, 0%, 97.6%, 0.3);
  border-color: hsla(0, 0%, 80.4%, 0.3);
}
.discord-embed .discord-embed-content {
  display: -webkit-flex;
  display: flex;
}
.discord-embed .discord-embed-thumbnail {
  max-width: 80px;
  max-height: 80px;
  margin-left: 16px;
  margin-top: 8px;
  border-radius: 4px;
  object-fit: contain;
  object-position: top center;
}
.discord-embed .discord-embed-author {
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 500;
  margin-top: 8px;
}
.discord-light-theme .discord-embed .discord-embed-author {
  color: #4f545c;
}
.discord-embed .discord-embed-author a {
  color: #fff;
}
.discord-light-theme .discord-embed .discord-embed-author a {
  color: #4f545c;
}
.discord-embed .discord-embed-author .discord-author-image {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
}
.discord-embed .discord-embed-title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
}
.discord-embed .discord-embed-title a {
  color: #00b0f4;
  font-weight: 600;
}
.discord-embed .discord-embed-description {
  margin-top: 8px;
}
.discord-embed .discord-embed-image {
  max-width: 100%;
  margin-top: 16px;
  border-radius: 4px;
}
.discord-embed .discord-embed-footer {
  color: #72767d;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.85em;
  margin-top: 8px;
}
.discord-embed .discord-embed-footer .discord-footer-image {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
}
.discord-embed .discord-embed-footer .discord-footer-separator {
  color: #3b3c42;
  font-weight: 700;
  margin: 0 4px;
}
.discord-light-theme
  .discord-embed
  .discord-embed-footer
  .discord-footer-separator {
  color: #e4e4e4;
}
.discord-embed .discord-embed-fields {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 8px;
}
.discord-embed .discord-embed-field {
  min-width: 100%;
  margin-top: 5px;
}
.discord-embed .discord-embed-field.discord-inline-field {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: auto;
          flex-basis: auto;
  min-width: 100px;
}
.discord-embed .discord-embed-field .discord-field-title {
  color: #72767d;
  font-weight: 500;
  margin-bottom: 2px;
}
.discord-light-theme .discord-embed .discord-embed-field .discord-field-title {
  color: #747f8d;
}
.discord-message .discord-mention {
  color: #7289da;
  background-color: rgba(114, 137, 218, 0.1);
  font-weight: 500;
  padding: 0 1px;
}
.discord-message .discord-mention:hover {
  color: #fff;
  background-color: rgba(114, 137, 218, 0.7);
}
.discord-message.discord-highlight-mention {
  background-color: rgba(250, 166, 26, 0.05);
  position: relative;
}
.discord-message.discord-highlight-mention:before {
  content: "";
  background-color: #faa61a;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
}
.discord-message.discord-highlight-mention:hover {
  background-color: rgba(250, 166, 26, 0.1);
}
.discord-message.discord-highlight-mention .discord-mention {
  background-color: unset !important;
}
.discord-message.discord-highlight-mention .discord-mention:hover {
  color: #7289da;
  text-decoration: underline;
}
.discord-messages {
  font-family: Roboto, sans-serif;
}



